import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const HomeView = () => import("@/views/HomeView.vue");
const VipView = () => import("@/views/VipView.vue");
const TaskView = () => import("@/views/TaskView.vue");
const GameHall = () => import("@/views/GameHall.vue");
const DiscountHallView = () => import("@/views/DiscountHallView.vue");
//------My------//
const MyView = () => import("@/views/MyView.vue");
const MySecurityPage = () => import("@/components/my/MySecurityPage.vue");
const AboutUsView = () => import("@/components/my/AboutUs.vue");
//------代理------//
const AgentView = () => import("@/views/AgentView.vue");
const AgentMember = () => import("@/components/agent/AgentMemberList.vue");
const AgentCommission = () =>
  import("@/components/agent/AgentComissionList.vue");
const AgentCommissionDetail = () =>
  import("@/components/agent/CommissionDetailNB.vue");

//------活动------//
const ActivityView = () => import("@/views/ActivityView.vue");
const ActivityInfoCustom = () =>
  import("@/components/activity/ActivityInfoCustom.vue");
const ActivityInfoAims = () =>
  import("@/components/activity/ActivityInfoAims.vue");
const ActivityPurchaseOrBet = () =>
  import("@/components/activity/ActivityPurchaseOrBet.vue");
const ActivityCutOneKnife = () =>
  import("@/components/activity/ActivityCutOneKnife.vue");
const ScoreRule = () => import("@/components/task/ScoreRule.vue");
//------提取------//
const WithdrawView = () => import("@/views/WithdrawView.vue");
const WithdrawRecord = () => import("@/components/shop/WithdrawRecord.vue");
const WithdrawDetail = () => import("@/components/shop/WithdrawDetail.vue");
const ChipStatements = () => import("@/components/shop/ChipStatements.vue");
//-----记录------//
const CashAccount = () => import("@/components/my/CashAccountTable.vue");
const UserAccountView = () => import("@/views/UserAccountView.vue");
const BetCasinoRecord = () =>
  import("@/components/my/BetCasinoRecordTable.vue");
const BetSportsRecord = () =>
  import("@/components/my/BetSportsRecordTable.vue");
const BetLotteryRecord = () =>
  import("@/components/my/BetLotteryRecordTable.vue");
const BetSportsRecordDetail = () =>
  import("@/components/my/BetSportsRecordDetail.vue");
const BetLotteryRecordDetail = () =>
  import("@/components/my/BetLotteryRecordDetail.vue");

// const PlayViewForPC = () => import("@/views/PlayViewForPC.vue");
// const PlayViewForQK = () => import("@/views/PlayViewForQK.vue");

const FAQVIEW = () => import("@/views/FAQ.vue");
const TransferView = () => import("@/views/TransferView.vue");
const TransferDetailView = () => import("@/views/TransferDetailView.vue");

const LoginView = () => import("@/views/LoginView.vue");
const RegisterView = () => import("@/views/RegisterView.vue");
const RegisterThirdView = () => import("@/views/RegisterThirdView.vue");
const ForgetPasswordView = () => import("@/views/ForgetPasswordView.vue");

//----- 消息------//
const NBUserMessage = () => import("@/components/home/NBUserMessage.vue");
const MessageList = () => import("@/components/message/MessageList.vue");
const AnnouncementList = () =>
  import("@/components/message/AnnouncementList.vue");
const NBUserMessageDetail = () =>
  import("@/components/home/NBUserMessageDetail.vue");

const ShopView = () => import("@/views/ShopView.vue");
const RechargeRecord = () => import("@/components/shop/RechargeRecord.vue");
const RechargeDetail = () => import("@/components/shop/RechargeDetail.vue");

//----- 返利------//
const RebateView = () => import("@/views/RebateView.vue");
const RebateRecord = () => import("@/components/rebate/RebateRecord.vue");
const RebateDetail = () => import("@/components/rebate/RebateDetail.vue");
const RebateRatio = () => import("@/components/rebate/RebateRatio.vue");
//----- 返利------//
const BonusDrawRecord = () => import("@/components/task/BonusDrawRecord.vue");
import { store } from "@/store";

// const Empty = {
//   template: "<div></div>",
// };

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { depth: 1100 },
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: { depth: 1101 },
  },
  {
    path: "/registerOther",
    name: "RegisterOther",
    component: RegisterThirdView,
    meta: { depth: 1102 },
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: ForgetPasswordView,
    meta: { depth: 1101 },
  },
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: { depth: 0, needKeep: true },
  },
  {
    path: "/vip",
    name: "Vip",
    component: VipView,
    meta: { depth: 10, unsupportGuest: true },
  },
  {
    path: "/bonusDrawRecord",
    name: "BonusDrawRecord",
    component: BonusDrawRecord,
    meta: { depth: 12 },
  },
  {
    path: "/invite",
    name: "Agent Invite",
    component: AgentView,
    meta: { requiresAuth: false, depth: 900 },
  },
  {
    path: "/invite/member",
    name: "AgentMember",
    component: AgentMember,
    meta: { requiresAuth: true, depth: 901 },
  },
  {
    path: "/invite/commission",
    name: "AgentCommission",
    component: AgentCommission,
    meta: { requiresAuth: true, depth: 901 },
  },
  {
    path: "/invite/commission/detail/:commissionId",
    name: "AgentCommissionDetail",
    component: AgentCommissionDetail,
    meta: { requiresAuth: true, depth: 902 },
  },
  {
    path: "/my",
    name: "My",
    component: MyView,
    meta: { depth: 0 },
  },
  {
    path: "/my/safety",
    name: "MySecurityPage",
    component: MySecurityPage,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 10 },
  },
  {
    path: "/my/aboutUs",
    name: "AboutUs",
    component: AboutUsView,
    meta: { depth: 10 },
  },
  {
    path: "/discountHall",
    name: "DiscountHallView",
    component: DiscountHallView,
    redirect: "/discountHall/activity",
    meta: { depth: 10, needKeep: true },
    children: [
      {
        path: "activity",
        name: "ActivityView",
        component: ActivityView,
        meta: { depth: 11, needKeep: true },
      },
      {
        path: "task",
        name: "TaskView",
        component: TaskView,
        meta: { depth: 11 },
      },
      {
        path: "rebate",
        name: "RebateView",
        component: RebateView,
        meta: { depth: 11 },
      },
    ],
  },
  {
    path: "/scoreRule/:title?/:rule?",
    name: "ScoreRule",
    component: ScoreRule,
    meta: { depth: 13 },
  },
  {
    path: "/rebateRecord",
    name: "RebateRecord",
    component: RebateRecord,
    meta: { depth: 12 },
  },
  {
    path: "/rebateDetail/:time/:type",
    name: "RebateDetail",
    component: RebateDetail,
    meta: { depth: 13 },
  },
  {
    path: "/rebateRatio/:categoryId/:platformId",
    name: "RebateRatio",
    component: RebateRatio,
    meta: { depth: 12 },
  },
  {
    path: "/activity/CustomDetail/:categoryId/:id/:img?",
    name: "ActivityInfoCustom",
    component: ActivityInfoCustom,
    meta: { depth: 12 },
  },
  {
    path: "/activity/aims",
    name: "ActivityInfoAims",
    component: ActivityInfoAims,
    meta: { depth: 12 },
  },
  {
    path: "/activity/purchaseOrBet",
    name: "PurchaseOrBet",
    component: ActivityPurchaseOrBet,
    meta: { depth: 12 },
  },
  {
    path: "/activity/cutOneKnife",
    name: "ActivityCutOneKnife",
    component: ActivityCutOneKnife,
    meta: { depth: 12 },
  },
  {
    path: "/withdraw",
    name: "Withdraw",
    component: WithdrawView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 10 },
  },
  {
    path: "/withdrawRecord",
    name: "WithdrawRecord",
    component: WithdrawRecord,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 11 },
  },
  {
    path: "/withdrawDetail",
    name: "WithdrawDetail",
    component: WithdrawDetail,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 12 },
  },
  {
    path: "/withdrawAudit",
    name: "ChipStatements",
    component: ChipStatements,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 11 },
  },
  {
    path: "/message",
    name: "Message",
    component: NBUserMessage,
    redirect: "/message/list/1",
    meta: { depth: 10 },
    children: [
      {
        path: "list/:type",
        component: MessageList,
        meta: { depth: 11 },
      },
      {
        path: "announcement",
        component: AnnouncementList,
        meta: { depth: 11 },
      },
    ],
  },
  {
    path: "/message/detail/:type/:messageId/",
    name: "MessageDetail",
    component: NBUserMessageDetail,
    meta: { depth: 12 },
  },
  {
    path: "/shop",
    name: "Shop",
    component: ShopView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1000 }, // 充值页面depth设置大一点，一些活动页面也会跳转
  },
  {
    path: "/rechargeRecord",
    name: "RechargeRecord",
    component: RechargeRecord,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1001 },
  },
  {
    path: "/rechargeDetail",
    name: "RechargeDetail",
    component: RechargeDetail,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 1002 },
  },
  {
    path: "/detailRecord/:type?",
    name: "CashAccount",
    component: CashAccount,
    meta: { requiresAuth: true, depth: 20 },
  },
  {
    path: "/betOrder",
    name: "betOrder",
    component: UserAccountView,
    redirect: "/betOrder/sports",
    meta: { depth: 10 },
    children: [
      {
        path: "casino",
        name: "casino",
        component: BetCasinoRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
      {
        path: "sports",
        name: "sports",
        component: BetSportsRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
      {
        path: "lottery",
        name: "lottery",
        component: BetLotteryRecord,
        meta: { requiresAuth: true, depth: 11 },
      },
    ],
  },
  {
    path: "/betOrder/sports/detail",
    name: "sportsDetail",
    component: BetSportsRecordDetail,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/betOrder/lottery/detail",
    name: "lotteryDetail",
    component: BetLotteryRecordDetail,
    meta: { requiresAuth: true, depth: 12 },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQVIEW,
    meta: { depth: 10 },
  },
  {
    path: "/transfer",
    name: "TransferView",
    component: TransferView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 30 },
  },
  {
    path: "/transfer/detail",
    name: "TransferDetail",
    component: TransferDetailView,
    meta: { requiresAuth: true, unsupportGuest: true, depth: 31 },
  },
  {
    path: "/gameHall",
    name: "GameHall",
    component: GameHall,
    meta: { depth: 20 },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const scrollMap = new Map<string, number>();
export const clearScrollMap = () => {
  // 登录回到首页，或者退出账号回到首页时，清空
  scrollMap.clear();
};

import { useCustomRouter, useRouterAnimate } from "@/router/custRouter";
import { showNBGuestLimitDialog } from "@/utils/dialog";
import { isNumeric } from "@/utils/util";
const needAnimate = useRouterAnimate();
const customRouter = useCustomRouter(router);

customRouter.beforeEach(async (to, from, next) => {
  if (isNumeric(from.meta.scrollTop)) {
    // 离开的页面需要记住当前滑动高度
    scrollMap.set(from.path, Number(from.meta.scrollTop));
  }

  if (scrollMap.has(to.path)) {
    // 即将进入的页面之前有记住滑动高度
    const scrollTop = scrollMap.get(to.path);
    to.meta.scrollTop = scrollTop;
    // 清除数据
    scrollMap.delete(to.path);
  } else {
    // 清除to的scrollTop，避免有缓存
    to.meta.scrollTop = null;
  }

  if (to.meta.requiresAuth && !store.getters.loginSuccess) {
    customRouter.push({ path: "/login", query: { redirect: from.path } });
    next(false);
    // next({ path: from.path, query: { ...to.query, ...from.query } });
  } else if (
    store.getters.loginSuccess &&
    store.getters.userType.isGuest &&
    to.meta.unsupportGuest
  ) {
    // 登录状态下，登录模式为游客。禁止进入的页面
    showNBGuestLimitDialog();
    next(false);
    // next({ path: from.path, query: { ...to.query, ...from.query } });
  } else {
    // switch (to.path) {
    routeAnimate(to, from, next);
  }
});

customRouter.beforeResolve(async (to) => {
  if (to.path === "/") {
    //回到首页时拿取弹窗
    store.commit("messagePopupBox/popMessage");
  }
});

const routeAnimate = (to: any, from: any, next: any) => {
  const toDepth = to.meta.depth;
  const fromDepth = from.meta.depth;

  if ((toDepth === 0 && fromDepth == void 0) || toDepth === fromDepth) {
    console.log(3333, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }
  if (!from.path) {
    console.log(3334, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }
  if (!needAnimate.value) {
    console.log(3335, toDepth, fromDepth);
    goRoute(to, from, next);
    return;
  }

  console.log(3336, toDepth, fromDepth);
  to.meta.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
  goRoute(to, from, next);
};

const goRoute = (to: any, from: any, next: any) => {
  const initialParams = from.query;
  const hasAgentCode = Object.prototype.hasOwnProperty.call(
    initialParams,
    "agentCode"
  );
  const hasChannelCode = Object.prototype.hasOwnProperty.call(
    initialParams,
    "channelCode"
  );
  const hasAppsFlyerId = Object.prototype.hasOwnProperty.call(
    initialParams,
    "appsFlyerId"
  );

  const toParams = to.query;
  const toHasAgentCode = Object.prototype.hasOwnProperty.call(
    toParams,
    "agentCode"
  );
  const toHasChannelCode = Object.prototype.hasOwnProperty.call(
    toParams,
    "channelCode"
  );
  const toHasAppsFlyerId = Object.prototype.hasOwnProperty.call(
    toParams,
    "appsFlyerId"
  );
  // 目的是每个页面都保持住agentCode
  if (
    (!toHasAgentCode && hasAgentCode) ||
    (!toHasChannelCode && hasChannelCode) ||
    (!toHasAppsFlyerId && hasAppsFlyerId)
  ) {
    const params = {} as any;
    // if (hasfbid) {
    //   params.fbid = initialParams.fbid;
    // }
    // if (hasFbclid) {
    //   params.fbclid = initialParams.fbclid;
    // }
    if (hasAgentCode) {
      params.agentCode = initialParams.agentCode;
    }
    if (hasChannelCode) {
      params.channelCode = initialParams.channelCode;
    }
    if (hasAppsFlyerId) {
      params.appsFlyerId = initialParams.appsFlyerId;
    }

    next({ ...to, query: { ...to.query, ...params } });
  } else {
    next();
  }
};

customRouter.afterEach(() => {
  needAnimate.value = false;
  // document.getElementById("nb-layout-body")?.scrollTo(0, 0);
});

export default router;
